import {useContext, useEffect, useState} from "react";
import NavContext from "../../../context/NavContext";
import {useLazyQuery, useMutation} from "@apollo/client";
import {DAILY_GET_CS_STOCK} from "../../../gql/stock";
import { CHECK_MAINTENANCE } from "../../../gql/auth";

const HdpOuViewCs = () => {
    // useContext
    const {setNavActive, setMainNav} = useContext(NavContext);
    // useState
    const [loading, setLoading] = useState(true);
    const [ netAmount, setnetAmount] = useState(null);
    const [ loadSettings, resultSettings ] = useLazyQuery(CHECK_MAINTENANCE);

    const [stock, setStock ] = useState(null);

    // useMutation
    const [ dailyGetCsStock ] = useMutation(DAILY_GET_CS_STOCK, {
        onError: (error) => {
            console.log(error);
        },
        onCompleted: (result) => {
            setStock(result.DailyCsStock.message);
            setLoading(false);
        }
    })


    // Start UseEffect
    useEffect(() => {
        setNavActive("hdpoucs");
        setMainNav("stockcs");
        setLoading(true);

        loadSettings();
        dailyGetCsStock({ variables: {message: "true"}});
    }, [loadSettings, dailyGetCsStock])

    useEffect(() => {
        if(resultSettings.data){
            setnetAmount(resultSettings.data.setting[0].net_amount);
        }
    }, [resultSettings]);
    // End UseEffect

    return(
        <>
            <div className="mt-5 mx-5">
                <p className="text-2xl md:text-3xl font-bold">HDP & OU</p>
            </div>

            <div className="w-full rounded-lg border border-gray-200 shadow-md my-5 overflow-y-auto">
                <table className="w-full whitespace-nowrap border-collapse bg-white text-left text-sm text-gray-500">
                    <thead className="bg-gray-50">
                        <tr className="text-center font-medium text-gray-900">
                            <th scope="col" className="px-2 py-4">Date</th>
                            <th scope="col" className="px-2 py-4">League</th>
                            <th scope="col" className="px-2 py-4">Match</th>
                        </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                    {
                        loading ?
                            <tr>
                                <td className="text-center px-2 py-4" colSpan="9"><img src={require('../../../icons/loading.gif')} className="w-5 inline-block mr-1" alt="loading"/>Loading</td>
                            </tr>
                            :
                            stock &&
                                stock.length > 0 ?
                                    stock.map((s,index) => (
                                        <>
                                            <tr className="text-center bg-red-200" key={index}>
                                                <td className="px-2 py-4">
                                                    {new Date(s.match_time).toLocaleString("en-US", { timeZone: "Asia/Yangon" }).split(",")[0]}
                                                    <br/>
                                                    {new Date(s.match_time).toLocaleString("en-US", { timeZone: "Asia/Yangon" }).split(",")[1]}
                                                </td>
                                                <td className="px-2 py-4">{s.league_name_en}</td>
                                                <td className="px-2 py-4">
                                                    <span className="font-bold text-blue-500">{s.home_team}</span> vs <span className="font-bold text-red-500">{s.away_team}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 py-4" colSpan="3">
                                                    <div className="flex justify-around">
                                                        {
                                                            Object.keys(s).map((key) => (
                                                                ( key !== "away_team" && key !== "home_team" && key !== "league_name_en" && key !== "away_team" && key !== "match_time" && key !== "match_id" ) &&
                                                                <div className="w-12 text-center mr-5" key={key}>
                                                                    <p className="bg-purple-200 font-bold py-1 mb-1">{key}</p>
                                                                    <p className={`${s[key] >= netAmount ? "border bg-amber-900 text-white rounded-lg" : "" } py-1`}>{Number(s[key]).toLocaleString("en-US")}</p>
                                                                </div>

                                                            ))
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    ))
                                :
                                    <tr>
                                        <td className="text-center text-red-600 px-2 py-4" colSpan="9">No Data</td>
                                    </tr>
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
};

export default HdpOuViewCs;