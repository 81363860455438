import {gql} from "@apollo/client";

const COMMISION_HISTORY = gql`
query MyQuery($user: users_bool_exp!, $status_slip: Boolean!,$slip_show: Boolean!, $startDate: timestamptz!, $endDate: timestamptz!) {
  commision_history(where: {bet_slip: {status_slip: {_eq: $status_slip}, user: $user, slip_show: {_eq: $slip_show}}, _and: {bet_slip: {created_at: {_gt: $startDate}, _and: {created_at: {_lt: $endDate}}}, _and: {rollback: {_eq: false}}}}, order_by: {id: desc}) {
        actual_commision
        bet_slip {
              balance
              bet_team
              body
              getback_balance
              goal_paung
              id
              over_under
              win_lose_cash
              body_team
              user {
                    id
                    username
                    super_code
                    senior_code
                    master_code
                    agent_code
                    user_code
              }
              created_at
        match {
              away_team
              home_team
              league_id
              id
              full_match
              half_score_1
              half_score_2
              score_1
              score_2
              rate_1
              rate_2
              }
              slip_show
        }
        slip_id
        percent_commision
        commision_amount
        id
        user {
              id
              username
              super_code
              senior_code
              master_code
              agent_code
              user_code
        }
        created_at
        updated_at
}
}
`
const CORRECT_SCORE_HISTORY = gql`
    query CORRECT_SCORE_HISTORY($user: users_bool_exp!, $slip_show: Boolean!, $status_slip: Boolean!, $startDate: timestamptz!, $endDate: timestamptz!) {
          cs_commision_history(where: {user: $user, correct_slip: {slip_show: {_eq: $slip_show}, status_slip: {_eq: $status_slip}, _and: {created_at: {_gt: $startDate}, _and: {created_at: {_lt: $endDate}}}}}, order_by: {id: desc}) {
                slip_id
                actual_commision
                commision_amount
                created_at
                id
                percent_commision
                user {
                      id
                      senior_code
                      super_code
                      user_code
                      master_code
                      agent_code
                      username
                }
                correct_slip {
                      id
                      balance
                      bet_score
                      final_cash
                      multiply
                      slip_show
                      status_slip
                      win_lose_cash
                      created_at
                      correct_score {
                            match {
                                  away_team
                                  home_team
                                  score_1
                                  score_2
                                  half_score_1
                                  half_score_2
                            }
                            AOS
                            score0_0
                            score0_1
                            score0_2
                            score0_3
                            score0_4
                            score1_0
                            score1_1
                            score1_2
                            score1_3
                            score1_4
                            score2_0
                            score2_2
                            score2_1
                            score2_3
                            score2_4
                            score3_0
                            score3_1
                            score3_2
                            score3_3
                            score3_4
                            score4_0
                            score4_1
                            score4_2
                            score4_3
                            score4_4
                      }
                }
          }
    }
`

export { COMMISION_HISTORY, CORRECT_SCORE_HISTORY };