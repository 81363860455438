import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { decodeUserToken } from "../composable/login";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

let userData;

const authLink = setContext((_, { headers }) => {
  userData = decodeUserToken();

  if (userData) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${userData.token}`,
      },
    };
  }

  return {
    headers: {
      ...headers,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (
        extensions.code === "invalid-headers" ||
        extensions.code === "invalid-jwt"
      ) {
        window.localStorage.removeItem("loggedUser");
        window.location.assign(`${window.location.origin}`);
      }
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    alert("network connection problem");
  }
});

const httpLink = new HttpLink({
  uri: "https://api.soccer22mm.com/v1/graphql",
  // uri: "https://testapi.soccer22mm.com/v1/graphql",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: "wss://api.soccer22mm.com:4443/v1/graphql",
    // url: "wss://testapi.soccer22mm.com:4443/v1/graphql",
    connectionParams: {
      authToken: userData ? userData.token : null,
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink,
});

export { authLink, errorLink, httpLink, client };
