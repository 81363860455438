import {createContext, useState} from "react";

const ReportContext = createContext();

const ReportContextProvider = ({children}) => {
    const [key, setKey] = useState(1);
    const [showCompany, setShowCompany] = useState(null);
    const [clickUserCode, setClickUserCode] = useState([]);

    return (
        <ReportContext.Provider value={{key, setKey, showCompany, setShowCompany, clickUserCode, setClickUserCode}}>
            {children}
        </ReportContext.Provider>
    )
};

export default ReportContext;
export {ReportContextProvider};