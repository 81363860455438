import {gql} from "@apollo/client";

const LOGIN = gql`
    mutation AdminLogIn($username: String!, $password: String!) {
        AdminLogIn(password: $password, username: $username) {
            accessToken
            message
            error
        }
    }
`;

const CHECK_MAINTENANCE = gql`
    query CHECK_MAINTENANCE {
        setting {
            id
            maintenance
            version
            net_amount
        }
    }
`

const CHECK_MAINTENANCE_LIVE = gql`
    subscription CheckMaintenance {
        setting {
        maintenance
    }
  }  
`;

export { LOGIN, CHECK_MAINTENANCE, CHECK_MAINTENANCE_LIVE };