import {createContext, useState} from "react";

const NavContext = createContext();

export const NavContextProvider = ({children}) => {
    const [showNav, setShowNav] = useState(false);
    const [navActive, setNavActive] = useState("");
    const [mainNav, setMainNav] = useState("");


    const navHandle = () => {
        setShowNav(!showNav);
    }

    return (
        <NavContext.Provider value={{navActive, setNavActive, mainNav, setMainNav, showNav, setShowNav, navHandle}}>
            {children}
        </NavContext.Provider>
    )
}

export default NavContext;