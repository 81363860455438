import {
    Route,
    BrowserRouter, Routes, useLocation
} from "react-router-dom";
import {lazy, Suspense, useContext} from "react";
import { AnimatePresence } from "framer-motion";
import NavContext from "../context/NavContext";
import HdpOuViewCs from "../view/stock/correctslip/HdpOuViewCs";

const Loading = lazy(() => import("../component/Loading"));
const LayoutView = lazy(() => import("../view/LayoutView"));
const NotFound = lazy(() => import("../view/NotFound"));
const MaintenanceView = lazy(() => import("../view/MaintenanceView"));

const LoginView = lazy(() => import("../view/LoginView"));
const ProfileView = lazy(() => import("../view/account/ProfileView"));
const AccountSettingView = lazy(() => import("../view/account/AccountSettingView"));
const AgentView = lazy(() => import("../view/member/AgentView"));
const SubUserView = lazy(() => import("../view/member/SubUserView"));
const PaymentView = lazy(() => import("../view/payment/PaymentView"));
const PaymentHistoryView = lazy(() => import("../view/payment/PaymentHistoryView"));
const MatchListView = lazy(() => import("../view/match/MatchListView"));
const TeamView = lazy(() => import("../view/match/TeamView"));
const HdpOuView = lazy(() => import("../view/stock/betslip/HdpOuView"));
const HdpOuFirstView = lazy(() => import("../view/stock/betslip/HdpOuFirstView"));
const ReportView = lazy(() => import("../view/report/betslip/ReportView"));
const DailyReportView = lazy(() => import("../view/report/betslip/DailyReportView"));
const PostponeView = lazy(() => import("../view/report/betslip/PostponeView"));
const CsReportView = lazy(() => import("../view/report/correctscore/ReportView"));
const CsDailyReportView = lazy(() => import("../view/report/correctscore/DailyReportView"));
const CsPostponeView = lazy(() => import("../view/report/correctscore/PostponeView"));

const MainRouter = () => {
    const location = useLocation();
    const { setShowNav } = useContext(NavContext);

    return (
        <Suspense fallback={<Loading/>}>
            <AnimatePresence mode="wait" onExitComplete={() => setShowNav(false)}>
                <Routes location={location} key={location.key}>
                    <Route index element={<LoginView/>}/>

                    <Route path="/" element={<LayoutView/>}>
                        {/*Start account Route*/}
                        <Route path="/profile" element={<ProfileView/>}/>
                        <Route path="/setting" element={<AccountSettingView/>}/>
                        {/*End account Route*/}

                        {/*Start member Route*/}
                        <Route path="/agent" element={<AgentView/>}/>
                        <Route path="/subuser" element={<SubUserView/>}/>
                        {/*End member Route*/}

                        {/*Start Payment Route*/}
                        <Route path="/quickpayment" element={<PaymentView/>}/>
                        <Route path="/paymenthistory" element={<PaymentHistoryView/>}/>
                        {/*End Payment Route*/}

                        {/*Start Management match*/}
                        <Route path="/matchlist" element={<MatchListView/>}/>
                        <Route path="/team" element={<TeamView/>}/>
                        {/*End Management match*/}

                        {/*Start report Management*/}
                        <Route path="/report" element={<ReportView/>}/>
                        <Route path="/dailyreport" element={<DailyReportView/>}/>
                        <Route path="/postponereport" element={<PostponeView/>}/>
                        {/*End report Management*/}


                        {/*Start report Management*/}
                        <Route path="/csreport" element={<CsReportView/>}/>
                        <Route path="/csdailyreport" element={<CsDailyReportView/>}/>
                        <Route path="/cspostponereport" element={<CsPostponeView/>}/>
                        {/*End report Management*/}

                        {/*Start Sock Management for company*/}
                        <Route path="/hdpou" element={<HdpOuView/>}/>
                        <Route path="/hdpoufirst" element={<HdpOuFirstView/>}/>
                        {/*End Sock Management for company*/}

                        {/*End Sock Management for bet slips*/}
                        <Route path="/hdpoucs" element={<HdpOuViewCs/>}/>
                        {/*End Sock Management for bet slips*/}
                    </Route>

                    <Route path="*" element={<NotFound/>}/>
                    <Route path="/maintenance" element={<MaintenanceView/>}/>
                </Routes>
            </AnimatePresence>
        </Suspense>
    )
};

export default MainRouter;