import './App.css';
import MainRouter from "./router/MainRouter";
import { NavContextProvider } from "./context/NavContext";
import { ApolloProvider } from "@apollo/client";
import { client } from "./hasura/config";
import {AlertContextProvider} from "./context/AlertContext";
import {AuthContextProvider} from "./context/AuthContext";
import {PaymentGqlContextProvider} from "./context/GqlContext";
import {ReportContextProvider} from "./context/ReportContext";
import {BrowserRouter} from "react-router-dom";

function App() {
    return (
        <ApolloProvider client={client}>
            <AuthContextProvider>
                <AlertContextProvider>
                    <NavContextProvider>
                        <PaymentGqlContextProvider>
                            <ReportContextProvider>
                                <BrowserRouter>
                                    <MainRouter/>
                                </BrowserRouter>
                            </ReportContextProvider>
                        </PaymentGqlContextProvider>
                    </NavContextProvider>
                </AlertContextProvider>
            </AuthContextProvider>
        </ApolloProvider>
    );
}

export default App;