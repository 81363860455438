import {gql} from "@apollo/client";

const DAILY_GET_STOCK = gql`
      mutation MyMutation($message: String!) {
            DailyStock(message: $message) {
            error
            message
            }
      }
`;


const DAILY_GET_CS_STOCK = gql`
      mutation MyMutation($message: String!) {
            DailyCsStock(message: $message) {
                  message
                  error
            }
      }
`;

export {DAILY_GET_STOCK, DAILY_GET_CS_STOCK};