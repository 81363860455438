import {gql} from "@apollo/client";

const USERS = gql`
query USERS($where: users_bool_exp) {
      users(order_by: {id: asc}, where: $where) {
            company_admin
            balance
            contact_name
            id
            super_code
            senior_code
            master_code
            agent_code
            user_code
            username
            active
            single_bet
            min_bet
            max_bet
            one_time_max_bet
            cs_min_bet
            cs_max_bet
            cs_single_bet
            cs_tax
      }
      users_aggregate(where: $where){
            aggregate {
                count
            }
      }
}
`;

const ALL_USER = gql`
        query USERS($where: users_bool_exp) {
              users(order_by: {id: asc}, where: $where) {
                    company_admin
                    balance
                    contact_name
                    id
                    super_code
                    senior_code
                    master_code
                    agent_code
                    user_code
                    username
                    active
              }
        }
`;


const INSERT_USER = gql`
    mutation MyMutation($password: String!, $username: String!, $maxBet: Int!, $cs_tax: numeric!, $cs_maxBet: Int!, $cs_commission: numeric!, $contactName: String!, $commission: numeric!, $min_bet: Int!, $cs_min_bet: Int!,$one_time_max_bet: Int!) {
          SignUp(commission: $commission, contactName: $contactName, cs_commision: $cs_commission, cs_maxbet: $cs_maxBet, cs_tax: $cs_tax, maxbet: $maxBet, password: $password, username: $username, min_bet: $min_bet, cs_minbet: $cs_min_bet, one_time_max_bet: $one_time_max_bet) {
                error
                message
          }
    }
`

const USERS_BY_PK = gql`
      query USER_BY_PK($id: Int!) {
            users_by_pk(id: $id) {
                  active
                  agent_code
                  balance
                  company_admin
                  contact_name
                  master_code
                  id
                  senior_code
                  super_code
                  user_code
                  username
                  min_bet
                  max_bet
                  one_time_max_bet
                  single_bet
                  cs_min_bet
                  cs_max_bet
                  cs_single_bet
                  cs_tax
            }
      }
`;

const CHANGE_PASSWORD = gql`
    mutation MyMutation($newPassword: String!, $userCode: String!) {
          ResetPassword(newPassword: $newPassword, userCode: $userCode) {
                error
                message
          }
    }
`;

const SINGLE_BET_TRANSFER = gql`
    mutation MyMutation($receiverCode: String!, $setting: String!, $singlebet: numeric!) {
          transferSingleBet(receiverCode: $receiverCode, setting: $setting, singlebet: $singlebet) {
                error
                message
          }
    }
`;

const MIN_BET_TRANSFER = gql`
    mutation MyMutation($setting: String!, $receiverCode: String!, $minbet: Int!) {
          transferMinxBet(minbet: $minbet, receiverCode: $receiverCode, setting: $setting) {
                error
                message
          }
    }
`

const MAX_BET_TRANSFER = gql`
    mutation MyMutation($receiverCode: String!, $setting: String!, $maxbet: Int!) {
          transferMaxBet(receiverCode: $receiverCode, setting: $setting, maxbet: $maxbet) {
                error
                message
          }
    }
`;

const ONE_TIME_MAX_BET_TRANSFER = gql`
      mutation MyMutation($one_time_max_bet: Int!, $receiverCode: String!, $setting: String!) {
            transferOneTimeMaxBet(one_time_max_bet: $one_time_max_bet, receiverCode: $receiverCode, setting: $setting) {
                  error
                  message
            }
      }    
`

const CS_TAX_TRANSFER = gql`
    mutation MyMutation($receiverCode: String!, $tax: numeric!) {
          transferTaxBet(receiverCode: $receiverCode, tax: $tax) {
                error
                message
          }
    }
`

const CHANGE_PASSWORD_LOGIN_USER = gql`
      mutation CHANGE_PASSWORD_LOGIN_USER($oldPassword: String!, $newPassword: String!) {
            ChangePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
                  error
                  message
            }
      }
`;

const CHANGE_NETAMOUNT_SETTING = gql`
      mutation CHANGE_NET_AMOUNT_SETTING($id: Int!, $net_amount: Int!) {
            update_setting_by_pk(pk_columns: {id: $id}, _set: {net_amount: $net_amount}) {
                  net_amount
            }
      }
`;

const ACCOUNT_SUSPEND = gql`
    mutation ACCOUNT_SUSPEND($suspendid: Int!) {
          AccountSuspend(suspendid: $suspendid) {
                error
                message
          }
    }
`;

const DELETE_USER_BY_PK = gql`
    mutation DELETE_USERS_BY_PK($id: Int!) {
          delete_users_by_pk(id: $id) {
                username
                user_code
                updated_at
                super_code
                senior_code
                id
                agent_code
                balance
          }
    }
`;

export { USERS, ALL_USER, INSERT_USER, USERS_BY_PK, CHANGE_PASSWORD, SINGLE_BET_TRANSFER, MIN_BET_TRANSFER, MAX_BET_TRANSFER, ONE_TIME_MAX_BET_TRANSFER, CS_TAX_TRANSFER, CHANGE_PASSWORD_LOGIN_USER, CHANGE_NETAMOUNT_SETTING, ACCOUNT_SUSPEND, DELETE_USER_BY_PK  };